import React from 'react';
import { shapes } from '@cimpress/react-components';

const { Robot } = shapes;

const Forbidden = () => (
  <div style={{ padding: '20px', width: '100%', textAlign: 'center' }}>
    <Robot status='warning' style={{ display: 'inline-block' }} />
    <p>You do not have access to view this Page.</p>
    <p>
      Please contact <a href={'mailto:ctgarudasquad@cimpress.com'}>CTGarudaSquad</a>.
    </p>
  </div>
);
export default Forbidden;
